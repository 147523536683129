<template>
  <CardTable
    name="User"
    :header="tableHeader"
    endpoint="backoffice-user?role=Super Admin"
    :breadcrumbs="breadcrumbs"
    :hasQueryParam="true"
  >
    <template v-slot:cell-no="{ row }">
      {{ row.no }}
    </template>
    <template v-slot:cell-role="{ row }">
      {{ row.role }}
    </template>
    <template v-slot:cell-username="{ row }">
      {{ row.username }}
    </template>
    <template v-slot:create>
      <button
        data-bs-toggle="modal"
        data-bs-target="#create"
        class="btn btn-success me-4"
        name="create user"
      >
        Create
      </button>
    </template>
  </CardTable>

  <div
    ref="modalRef"
    class="modal fade"
    id="create"
    tabindex="-1"
    aria-labelledby="create"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header fs-3">Create New User</div>
        <div class="modal-body px-12">
          <form @submit.prevent="null">
            <input
              type="text"
              class="form-control mb-3"
              placeholder="Username"
              v-model="inputRef.username"
            />
            <input
              type="password"
              class="form-control mb-3"
              placeholder="Password"
              v-model="inputRef.password"
            />
            <input
              type="password"
              class="form-control mb-3"
              placeholder="Confirm Password"
              v-model="inputRef.password2"
            />
            <select
              :class="!inputRef.role && 'text-gray-500'"
              class="form-select mb-3"
              v-model="inputRef.role"
            >
              <option hidden value="">Select Role</option>
              <option value="Admin">Admin</option>
            </select>
            <div class="d-flex justify-content-end">
              <button @click="createUser()" class="btn btn-primary">
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import CardTable from '@/components/kt-datatable/CardTable.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { hideModal } from '@/core/helpers/dom';

export default {
  name: 'UserManagement',
  components: {
    CardTable,
  },
  setup() {
    const breadcrumbs = ['User Management', ['User Lists']];
    const tableHeader = [
      {
        key: 'no',
        name: 'No.',
        sortable: true,
      },
      {
        name: 'Role',
        key: 'role',
        sortable: true,
      },
      {
        name: 'Username',
        key: 'username',
        sortable: true,
      },
    ];

    const inputRef = ref({
      username: '',
      password: '',
      password2: '',
      role: '',
    });
    const modalRef = ref(null);

    const createUser = async () => {
      if (Object.values(inputRef.value).includes('')) {
        Swal.fire({
          text: 'Please fill all fields',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
        return;
      }

      if (inputRef.value.password !== inputRef.value.password2) {
        Swal.fire({
          text: 'Password not match',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
        return;
      }

      await ApiService.post('auth/register', {
        username: inputRef.value.username,
        password: inputRef.value.password,
        role: inputRef.value.role,
      });

      inputRef.value = {
        username: '',
        password: '',
        password2: '',
        role: '',
      };

      hideModal(modalRef.value);

      Swal.fire({
        text: 'New User has been created',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Return to User Lists',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });
    };

    return {
      tableHeader,
      breadcrumbs,
      inputRef,
      createUser,
      modalRef,
    };
  },
};
</script>

<style></style>
